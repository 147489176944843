<template>
    <v-row    
        no-gutters               
        class="fill-height py-5 px-2">
        <v-col cols="6">
        <v-data-table 
            @click:row="getTimeline"
            fixed-header
            :items-per-page=-1
            hide-default-footer
            item-key="id" 
            single-select
            sort-by="userSessionDate"
            :sort-desc="true"
            :height="viewPortHeight / 2"
            :headers = "userSessionHeader"
            :items = "userSessionList"
            loading-text="Loading... Please wait"
            :loading = "loading">
            <template v-slot:item.count="{item}">
                {{item.id}}
            </template>                                            
            <template v-slot:item.userSessionDate="{item}">
                {{item.userSessionDate | formatDate}}
            </template>
            <template v-slot:item.userSessionDuration="{item}">
                {{item.userSessionDuration | formatDuration}}
            </template>                      
            <template v-slot:item.userSessionStartTime="{item}">
                {{item.userSessionStartTime | formatTime}}
            </template>                      
            <template v-slot:item.userSessionEndTime="{item}">
                {{item.userSessionEndTime | formatTime}}
            </template>                      
        </v-data-table>
        </v-col>
        <v-col cols="6" align="center">
            <h5 class="text-h5 primary--text">Session Timeline</h5>
            <span v-if="timeline === null">Select a session to view the timeline.</span>
            <v-card class="pr-3 mt-5" v-else>                      
                <span v-if="timeline.length === 0">
                    User detected but no interaction took place.
                </span>                      
                <v-timeline                         
                    v-else
                    dense 
                    clipped>                        
                    <v-virtual-scroll 
                        :height="viewPortHeight / 2"
                        item-height="120"
                        bench="0" 
                        :items="timeline">
                        <template v-slot:default="{item}">                                                 
                            <v-timeline-item
                                v-if="item.requestType.toLowerCase() === 'session'"  
                                :key="Math.random()"
                                :color="item.action.toLowerCase() === 'user left'? 'error' : 'success'"
                                :icon="item.action.toLowerCase() === 'user left'? 'mdi-logout-variant' : 'mdi-login-variant'"
                                class="mb-4" 
                                fill-dot>
                                <v-row no-gutters>
                                    <v-col 
                                        class="text-left"
                                        cols="9" >                  
                                        <span class="caption">Module: </span>
                                        <span class="caption font-weight-medium">Recognition</span>
                                        <br>                  
                                        <span class="caption">Action: </span>
                                        <span class="caption font-weight-medium">{{item.action}}</span>
                                        <br>
                                        <span class="caption">Response: </span>
                                        <span class="caption font-weight-medium">{{item.response | trimLength}}</span>
                                    </v-col>
                                    <v-col class="text-right" >                              
                                        {{item.timeStamp | formatStringTime}}
                                    </v-col>
                                </v-row>          
                                <v-divider></v-divider>                  
                            </v-timeline-item>                        
                            <v-timeline-item
                                v-else-if="item.requestType.toLowerCase() === 'speech'"  
                                :key="Math.random()"
                                color="orange lighten-2"
                                icon="mdi-bullhorn-outline"
                                class="mb-4" 
                                fill-dot>
                                <v-row no-gutters>
                                    <v-col 
                                        class="text-left"
                                        cols="9" >                  
                                        <span class="caption">Module: </span>
                                        <span class="caption font-weight-medium">{{item.module}}</span>
                                        <br>                  
                                        <span class="caption">Question: </span>
                                        <span class="caption font-weight-medium">{{item.action}}</span>
                                        <br>
                                        <span class="caption">Response: </span>
                                        <span class="caption font-weight-medium">{{item.response | trimLength}}</span>
                                    </v-col>
                                    <v-col class="text-right" >                              
                                        {{item.timeStamp | formatStringTime}}
                                    </v-col>
                                </v-row>          
                                <v-divider></v-divider>                  
                            </v-timeline-item>
                            <v-timeline-item
                                v-else-if="item.requestType.toLowerCase() === 'email'"  
                                :key="Math.random()"
                                color="blue-grey lighten-2"
                                icon="mdi-email-outline"
                                class="mb-4" 
                                fill-dot>
                                <v-row no-gutters>
                                    <v-col 
                                        class="text-left"
                                        cols="9" >                  
                                        <span class="caption">Module: </span>
                                        <span class="caption font-weight-medium">{{item.module}}</span>
                                        <br>                  
                                        <span class="caption">Action: </span>
                                        <span class="caption font-weight-medium">{{item.action}}</span>
                                        <br>
                                        <span class="caption">Attachemnt: </span>
                                        <span class="caption font-weight-medium">{{item.response | trimLength}}</span>
                                    </v-col>
                                    <v-col class="text-right" >                              
                                        {{item.timeStamp | formatStringTime}}
                                    </v-col>
                                </v-row>          
                                <v-divider></v-divider>                  
                            </v-timeline-item>
                            <v-timeline-item
                                v-else                                 
                                :key="Math.random()"
                                icon="mdi-gesture-tap"
                                class="mb-4" 
                                fill-dot>
                                <v-row no-gutters>
                                <v-col 
                                    class="text-left"
                                    cols="9" >
                                    <span class="caption">Module: </span>
                                    <span class="caption font-weight-medium">{{item.module}}</span>
                                    <br>
                                    <span class="caption">Action: </span>
                                    <span class="caption font-weight-medium">{{item.action}}</span>
                                    <br>
                                    <span class="caption">Response: </span>
                                    <span class="caption font-weight-medium">{{item.response | trimLength}}</span>
                                </v-col>
                                <v-col class="text-right" >                              
                                    {{item.timeStamp | formatStringTime}}
                                </v-col>                            
                                </v-row>                            
                                <v-divider></v-divider>
                            </v-timeline-item>                        
                        </template>
                    </v-virtual-scroll>                        
                </v-timeline>
            </v-card>                           
        </v-col>
    </v-row> 
</template>

<script>
    import moment from 'moment';

    export default{    
        name: 'userSession',
        props:{            
            userSessionList: Array,
            loading: Boolean,
        },   
        data(){
            return{
                timeline: null,
                selectedSessionId: null,
                userSessionHeader: [
                    {text: 'Session ID', align: 'id', value: 'count'},  
                    {text: 'Date', value: 'userSessionDate'},  
                    {text: 'Start Time', value: 'userSessionStartTime'},
                    {text: 'End Time', value: 'userSessionEndTime'},
                    {text: 'Duration', value: 'userSessionDuration'}, 
                ]
            }
        },     
        computed:{
            viewPortHeight(){
                return window.innerHeight
            },
        },
        methods:{
            getTimeline(item, row){        
                this.timeline = JSON.parse(item.userSessionLogs);                          
                row.select(true);
                this.selectedSessionId=item.id
            }
        },
        filters:{
            formatStringTime(val){
                if(!val) {return '-'}                    
                return moment(val).format('LTS')
            },
            trimLength(val){
                if(val === null || val === '') return val
                if(val.length <= 50) {return val}
                return `${val.substring(0,80)}...`
            },
            formatTime(val){
                if(!val) {return '-'}          
                let date = val.toDate();
                return moment(date).format('LT')
            },
            formatDate(val){
                if(!val) {return '-'}
                let date = val.toDate();
                return moment(date).format('L')
            },
            formatDuration(val){
                if(!val) {return '-'}                    
                return moment(val).format('mm:ss')
            },
        }
    }
</script>

<style>
    tr.v-data-table__selected {
        background: #1976d2 !important;
        color: #ffff !important;
    }
</style>