<template>
    <v-data-table    
        fixed-header
        :items-per-page=-1
        hide-default-footer                    
        single-select
        sort-by="timeStamp"
        :sort-desc="true"
        :height="viewPortHeight / 2"                
        :loading="loading"
        :headers="analysisHeader"
        :items="analysisList"
        :expanded.sync="expanded"
        item-key="id"
        single-expand
        show-expand
        >
        <template v-slot:item.transcript="{item}">
            {{item.transcript | trimLength}}
        </template>
        <template v-slot:item.result.answers[0].score="{item}">
            <v-chip
                    dark
                    small
                    :color="getColor(item.result.answers[0].score)">
                {{item.result.answers[0].score}}
            </v-chip>
        </template>
        <template v-slot:item.result.answers[0].answer="{item}">                    
            {{item.result.answers[0].answer | trimLength}}
        </template>
        <template v-slot:item.result.answers[0].questions[0]="{item}">
            <span v-if="item.result.answers[0].questions.length > 0">
                {{item.result.answers[0].questions[0] | trimLength}}
            </span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-2">
                <span class="font-weight-medium body-1 primary--text ">
                    More information:
                </span>
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Questions
                            </th>
                            <th class="text-left">
                                Answers
                            </th>
                            <th class="text-left">
                                Score
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                v-for="(it,index) in item.result.answers"
                                :key="index">
                            <td>{{ it.questions[0] | trimLength }}</td>
                            <td>{{ it.answer | trimLength }}</td>
                            <td>{{ it.score }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </td>
        </template>
        <template v-slot:item.timeStamp="{item}">
            {{item.timeStamp | formatDateFromNow}}
        </template>
    </v-data-table>
    
</template>

<script>
import moment from 'moment'

export default {
    name: 'qnaAnalytics',
    props:{
        analysisList: Array,
        loading: Boolean,
    },
    data(){
        return{
            expanded: [],
            analysisHeader: [
                {text: 'Question Asked', align: 'start', value:'transcript'},
                {text: 'Top Response Question', value:'result.answers[0].questions[0]'},
                {text: 'Top Response Answer', value:'result.answers[0].answer'},
                {text: 'Confidence Score', align: '', value:'result.answers[0].score'},
                { text: 'TimeStamp', value: 'timeStamp'},
                { text: 'More Information', value: 'data-table-expand' },
            ],
        }
    },
    computed:{
        viewPortHeight(){
          return window.innerHeight
        },

    },
    methods:{        
        getColor(score){
          if(score >= 76){
            return 'success';
          }
          else if(score >= 60){
            return 'amber';
          }
          else {
            return 'error';
          }
        },
    },
    filters:{   
        formatDate(val){
          if(!val) {return '-'}
          let date = val.toDate();
          return moment(date).format('L')
        },
        formatDuration(val){
          if(!val) {return '-'}                    
          return moment(val).format('mm:ss')
        },
        formatTime(val){
          if(!val) {return '-'}          
          let date = val.toDate();
          return moment(date).format('LT')
        },
        formatStringTime(val){
          if(!val) {return '-'}                    
          return moment(val).format('LTS')
        },
        formatDateFromNow(val){
          if(!val) {return '-'}
          let date = val.toDate();
          return moment(date).fromNow();
        },
        trimLength(val){
            if(typeof val !== 'string') return val
          if(val === null) return val
          if(val === '') return val
          if(val.length <= 100) {return val}
          return `${val.substring(0,50)}...`
        }
      },
}
</script>
