var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"fixed-header":"","items-per-page":-1,"hide-default-footer":"","single-select":"","sort-by":"timeStamp","sort-desc":true,"height":_vm.viewPortHeight / 2,"loading":_vm.loading,"headers":_vm.analysisHeader,"items":_vm.analysisList,"expanded":_vm.expanded,"item-key":"id","single-expand":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.transcript",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trimLength")(item.transcript))+" ")]}},{key:"item.result.answers[0].score",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","small":"","color":_vm.getColor(item.result.answers[0].score)}},[_vm._v(" "+_vm._s(item.result.answers[0].score)+" ")])]}},{key:"item.result.answers[0].answer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trimLength")(item.result.answers[0].answer))+" ")]}},{key:"item.result.answers[0].questions[0]",fn:function(ref){
var item = ref.item;
return [(item.result.answers[0].questions.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("trimLength")(item.result.answers[0].questions[0]))+" ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-2",attrs:{"colspan":headers.length}},[_c('span',{staticClass:"font-weight-medium body-1 primary--text "},[_vm._v(" More information: ")]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Questions ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Answers ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Score ")])])]),_c('tbody',_vm._l((item.result.answers),function(it,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm._f("trimLength")(it.questions[0])))]),_c('td',[_vm._v(_vm._s(_vm._f("trimLength")(it.answer)))]),_c('td',[_vm._v(_vm._s(it.score))])])}),0)]},proxy:true}],null,true)})],1)]}},{key:"item.timeStamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateFromNow")(item.timeStamp))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }